import React from 'react'

import SEO from '../components/seo'
import StatusCode from '../components/StatusCode'

const MaintenancePage = () => (
  <>
    <SEO title="503 Maintenance" />
    <StatusCode code="503" />
  </>
)

export default MaintenancePage
