import React from 'react'
import { Link as LinkComponent } from 'gatsby'
import media from 'styled-media-query'
import { string } from 'prop-types'
import styled from 'styled-components'

import Layout, { TopBar, Footer } from '@/components/Layout'

import { white } from '@/style-guide/colors'
import Button from '@/style-guide/Button'

import bgImageLarger from '@/assets/images/bg_header-larger.svg'
import bgImageSmall from '@/assets/images/bg_header-small.svg'
import maintenance from '@/assets/images/maintenance.svg'
import search from '@/assets/images/search.svg'

const Container = styled.div`
  background-color: #f7f7f7;
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  padding-bottom: 40px;
  margin-top: 0px;
  background-image: url(${bgImageSmall});
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 1000px;
  padding: 40px 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${media.greaterThan('medium')`
    margin-top: 0px;
    background-image: url(${bgImageLarger});
  `}
`

const Title = styled.span`
  font-weight: bold;
  font-size: 36.3969px;
  line-height: 46px;
  text-align: center;
  color: ${white};
`

const Description = styled.span`
  font-size: 14px;
  line-height: 24px;
  padding: 10px 0px;
  text-align: center;
  color: ${white};
`

const Code = styled.span`
  font-weight: bold;
  font-size: 136.489px;
  line-height: 148px;
  background: -webkit-linear-gradient(#4f83e0, #83d0ee);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`

const StyledButton = styled(Button)`
  width: 345px;
  height: 74px;
  max-width: unset;
  min-height: unset;
  margin-top: 60px;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
`

const Img = styled.img`
  padding-top: 30px;
  max-height: 300px;
  max-width: 300px;
`

const Link = styled(LinkComponent)`
  text-decoration: none;
`

const data = {
  503: {
    img: maintenance,
    title: "Sorry, We're under maintenance",
    description: 'Don’t worry though there’s always a way to go back home.'
  },
  404: {
    img: search,
    title: 'Sorry, 404 Page not found',
    description: 'Unfortunately the page you are looking for has been moved or deleted.'
  }
}

const StatusCode = ({ code = '503' }) => {
  const { title, description, img } = data[code]
  return (
    <Layout>
      <Container>
        <TopBar />
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Img src={img} alt="Code warning" />
          <Code>{code}</Code>
          <Link to="/">
            <StyledButton label="GO BACK TO HOMEPAGE" />
          </Link>
        </Content>
        <Footer />
      </Container>
    </Layout>
  )
}

StatusCode.propTypes = {
  code: string
}

export default StatusCode
